import { EventsModule as events } from "../Modules/EventsModule";

window.addEventListener('load', function () {
    events.Init();
});


document.addEventListener('widgetLoaded', function (args) {
    DEBUG && console.info(args);
    if (args.detail.model.Name === "Events") {
        DEBUG && console.info('**************************************************');
        DEBUG && console.info('=== Widget Reload Event ===')
        DEBUG && console.info('Element: ', args.detail.element);
        DEBUG && console.info('**************************************************');
        events.Init();
    }
});