import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";
import { UtilService as util } from "../Services/UtilService";
import 'add-to-calendar-button';

const eventCardTemplate = 'EventCard';
const eventModalTemplate = 'EventCardModal';

const nextFeaturedEventTemplate = "NextFeaturedEventCard";
const campusFeaturedEventTemplate = "NextFeaturedEventCard";
const upcomingEventTemplate = "UpcomingEvents";


const nextFeaturedEventCardTemplate = "NextFeaturedEventSimpleCard";
const NextFeaturedEventSimpleCardDefault = "NextFeaturedEventSimpleCardDefault";

export class EventsModule {
    static async Init() {

        DEBUG && console.info('*--> Events Module Init');

        var elements = document.querySelectorAll('[data-component="eventCards"]');

        elements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var registrationUrl = element.getAttribute('data-registrationUrl');
            var openEventId = element.getAttribute('data-eventId');
            var showCampus = element.getAttribute('data-showCampus');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Registration Url:        ${registrationUrl}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Open Event_ID:           ${openEventId}`);
            DEBUG && console.info(`Show Campus:             ${showCampus}`);
            DEBUG && console.info('**************************************************');

            this.InitEventCards(element, congregationId, registrationUrl, openEventId, showCampus);
        });


        // Check Init for nextFeaturedEvent
        var nextFeaturedEventElements = document.querySelectorAll('[data-component="nextFeaturedEvent"]');

        nextFeaturedEventElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var registrationUrl = element.getAttribute('data-registrationUrl');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Registration Url:        ${registrationUrl}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info('**************************************************');

            this.InitNextFeaturedEvent(element, congregationId, registrationUrl, nextFeaturedEventTemplate);
        });


        // Check Init for nextFeaturedEventCard
        var nextFeaturedEventCardElements = document.querySelectorAll('[data-component="nextFeaturedEventCard"]');

        nextFeaturedEventCardElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var registrationUrl = element.getAttribute('data-registrationUrl');
            var eventsUrl = element.getAttribute('data-eventsUrl');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Registration Url:        ${registrationUrl}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info('**************************************************');

            this.InitNextFeaturedEvent(element, congregationId, registrationUrl, eventsUrl, nextFeaturedEventCardTemplate);
        });


        // Check Init for campusFeaturedEvent
        var campusFeaturedEventElements = document.querySelectorAll('[data-component="campusFeaturedEvent"]');

        campusFeaturedEventElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var registrationUrl = element.getAttribute('data-registrationUrl');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Registration Url:        ${registrationUrl}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info('**************************************************');

            this.InitCampusFeaturedEvent(element, congregationId, registrationUrl);
        });


        // Init Upcoming Events
        // upcomingEventCards
        var upcomingEventCards = document.querySelectorAll('[data-component="upcomingEventCards"]');

        upcomingEventCards.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var registrationUrl = element.getAttribute('data-registrationUrl');
            var showCampus = element.getAttribute('data-showCampus');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Registration Url:        ${registrationUrl}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Show Campus:             ${showCampus}`);
            DEBUG && console.info('**************************************************');

            this.InitUpcomingEventCards(element, congregationId, registrationUrl, showCampus);
        });
    }

    //
    // Init: Setup Events Cards
    //
    static async InitEventCards(element, congregationId, registrationUrl, openEventId, showCampus) {
        var data = await this.GetEvents(congregationId);
        var eventData = { "events": data, "registrationUrl": registrationUrl, "eventModal": `modal${element.id}`, "showCampus": `${showCampus}` };

        var content = await TemplateService.GetRenderedTemplate(eventCardTemplate, eventData);

        element.innerHTML = content;

        this.EventCardsAddModalEvents(element.id);

        if (openEventId) {
            const modalElement = document.getElementById(`modal${element.id}`);
            this.LoadEventCardModal(modalElement, openEventId, `${registrationUrl}?id=`, true);
        }
    }

    //
    // Init: Setup Next Featured Event
    //
    static async InitNextFeaturedEvent(element, congregationId, registrationUrl, eventsUrl, template) {
        var data = await this.GetNextFeaturedEvent(congregationId);

        if (data.Event_ID > 0) {
            var eventData = { "event": data, "registrationUrl": registrationUrl, "eventModal": `modal${element.id}` };
            var content = await TemplateService.GetRenderedTemplate(template, eventData);
            element.innerHTML = content;
            this.EventCardsAddModalEvents(element.id);
        }
        else {
            var spanishMode = false;

            var currentUrl = window.location.href;

            if (currentUrl.includes("espanol")) {
                spanishMode = true;
            }

            var eventData = { "eventsUrl": eventsUrl, "spanishMode": spanishMode };
            var content = await TemplateService.GetRenderedTemplate(NextFeaturedEventSimpleCardDefault, eventData);
            element.innerHTML = content;            
        }
    }

    //
    // Init: Setup Campus Featured Event
    //
    static async InitCampusFeaturedEvent(element, congregationId, registrationUrl) {
        var data = await this.GetCampusFeaturedEvent(congregationId);
        var eventData = { "event": data, "registrationUrl": registrationUrl, "eventModal": `modal${element.id}` };

        var content = await TemplateService.GetRenderedTemplate(campusFeaturedEventTemplate, eventData);

        element.innerHTML = content;

        this.EventCardsAddModalEvents(element.id);
    }

    //
    // Init: Setup Upcoming Event Cards
    //
    static async InitUpcomingEventCards(element, congregationId, registrationUrl, showCampus) {
        var data = await this.GetUpcomingEvents(congregationId);
        var eventData = { "events": data, "registrationUrl": registrationUrl, "eventModal": `modal${element.id}`, "showCampus": `${showCampus}` };

        var content = await TemplateService.GetRenderedTemplate(upcomingEventTemplate, eventData);

        element.innerHTML = content;

        this.EventCardsAddModalEvents(element.id);
    }


    //
    // Init: Add Modal Listener Event Cards
    //
    static EventCardsAddModalEvents(elementId) {
        const element = document.getElementById(`modal${elementId}`);
        element.addEventListener('show.bs.modal', (evt) => {
            DEBUG && console.log('Modal Called');
            const eventId = evt.relatedTarget.getAttribute('data-id');
            const registrationUrl = evt.relatedTarget.getAttribute('data-registrationUrl');
            DEBUG && console.log(eventId);

            this.LoadEventCardModal(element, eventId, registrationUrl);
        });

        element.addEventListener('shown.bs.modal', (evt) => {
            DEBUG && console.log('Modal now Shown');
        });
    }

    static async LoadEventCardModal(modalElement, eventId, registrationUrl, manualModalOpen) {
        var data = await this.GetEvent(eventId);
        // Setup Data
        var eventData = { "event": data, "registrationUrl": registrationUrl };
        DEBUG && console.info(eventData);

        var content = await TemplateService.GetRenderedTemplate(eventModalTemplate, eventData);
        const container = modalElement.querySelector('.modal-content');

        container.innerHTML = content;

        if (manualModalOpen) {
            util.openModal(`${modalElement.id}`);
        }

        // Add Copy Button
        const btnCopy = document.getElementById("btnCopy");
        util.addCopyUrl(btnCopy, 'id', eventId);
    }

    static async GetEvents(congregationId) {
        var data = await api.getData(`Events/GetEvents?congregationId=${congregationId}`);
        DEBUG && console.info(data);
        return data;        
    }

    static async GetNextFeaturedEvent(congregationId) {
        var data = await api.getData(`Events/GetNextFeaturedEvent?congregationId=${congregationId}`);
        DEBUG && console.info(data);
        return data;
    }

    static async GetCampusFeaturedEvent(congregationId) {
        var data = await api.getData(`Events/GetCampusFeaturedEvent?congregationId=${congregationId}`);
        DEBUG && console.info(data);
        return data;
    }

    static async GetUpcomingEvents(congregationId) {
        var data = await api.getData(`Events/GetUpcomingEvents?congregationId=${congregationId}`);
        DEBUG && console.info(data);
        return data;
    }

    static async GetEvent(eventId) {
        var data = await api.getData(`Events/GetEvent/${eventId}`);
        DEBUG && console.info(data);
        return data;
    }
}